import {ChatPlugin} from '../Chat';

function ChatPluginPhrases(formPanelNode) {
    this.chat = null;

    var that = this;

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.onPrivilegesUpdate = function() {

        if(!that.chat.hasPrivileges('phrases', 'reload')) {
            return false;
        }

        if($('#chat-form-phrases').length) {
            return false;
        }
        var config = $('#chat-form-config');

        if(config.length == 0) {
            return false;
        }

        var button = $('<li />', {id: 'chat-form-phrases'}).text('Przeładuj zajawki').on('click', function() {
            that.chat.send('$adages reload');
            setTimeout(function() {
                that.chat.send('$dices reload');
            }, 1000);

        }).appendTo(config.children('ul'));
    };

    this.messageFilter = function(msg, messageNode) {
        return messageNode;
    };
}
ChatPluginPhrases.prototype = ChatPlugin;

export {ChatPluginPhrases}
