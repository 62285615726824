import {ChatPlugin} from '../Chat';

function ChatPluginHighlight(messagesNode) {
    this.chat = null;
    this.highlight = [];
    var that = this;

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.messageFilter = function(msg, messageNode) {
        if(this.highlight.length > 0) {
            var $node = messageNode.find('span');
            var content = $node.html().replace(
                new RegExp('(' + that.highlight.join('|') + ')', 'gmi'),
                '<mark>$1</mark>'
            );

            messageNode.find('span').html(content);

        }

        return messageNode;
    };

    this.highlight = function(text) {
        return text.replace(
            new RegExp('(' + that.highlight.join('|') + ')', 'gim'),
            '<mark>$1</mark>'
        );
    };

    this.onSettingsChange = function() {
        var highlight = [];

        $.each(that.chat.settings['highlight'].split(','), function() {
            highlight.push($.trim(this));
        });

        this.highlight = highlight.filter(function(e) {
            return e.length > 1;
        });

        messagesNode.find('.message-user').each(function() {
            var node = $(this).find('span');
            if(that.highlight.length > 0) {
                var test = that.highlight.join('|') + '|alamadziwnegokota';
                
                node.html(node.html().replace(
                    new RegExp('(' + test + ')', 'gmi'),
                    '<mark>$1</mark>'
                ));
            }

        });
    };
}
ChatPluginHighlight.prototype = ChatPlugin;

export {ChatPluginHighlight}
