import {ChatPlugin} from '../Chat';

function ChatPluginTime(messagesNode) {
    this.chat = null;
    var that = this;


    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.messageFilter = function(msg, messageNode) {
        var $timeNode = messageNode.find('time');

        $timeNode.attr('data-time', msg.time);

        $timeNode.text(that.formatTime(msg.time));

        return messageNode;
    };

    this.onSettingsChange = function() {
        messagesNode.find('.message-user').each(function() {
            $(this).find('time').text(that.formatTime($(this).find('time').attr('data-time')));
        });
    };

    this.formatTime = function(time) {
        var parts = time.split(':');

        if(that.chat.settings['seconds'] != 'true' && that.chat.settings['seconds'] != true) {
            return parts[0] + ':' + parts[1];
        } else {
            return parts[0] + ':' + parts[1] + ':' + parts[2];
        }
    };

}
ChatPluginTime.prototype = ChatPlugin;

export {ChatPluginTime}
