function Chat(container, formContainer) {
    this.websocket = null;
    this.plugins = [];
    this.time = 0;
    this.timeCounter = null;
    this.privileges = {};
    this.settings = {};
    this.port = 9000;
    this.updateTimer = null;

    var that = this;
    var connectionFailed = false;

    this.init = function() {
        this.websocket = new WebSocket("wss://heroes.net.pl:" + port);
        this.websocket.onopen = this.onOpen;
        this.websocket.onerror = this.onError;
        this.websocket.onclose = this.onClose;
        this.websocket.onmessage = this.onMessage;

        this.form.init();

        return this.websocket;
    };

    this.form = {
        formNode: $('<form />', {id: 'chat-form'}),
        inputNode: $('<input />', {type: 'text', id: 'chat-form-message', autocomplete: 'off', placeholder: 'Wpisz wiadomość...'}),
        submitNode: $('<div />', {id: 'chat-form-message-submit'}).html('<span class="icon-envelop"></span>'),
        inputContainerNode: $('<div />', {id: 'chat-form-message-container'}),
        submitContainerNode: $('<div />', {id: 'chat-form-submit-container'}),
        generated: false,

        init: function() {
            if(this.generated == true) {
                return;
            }

            this.submitNode.on('click', function() {
                that.form.formNode.submit();
            });


            this.formNode.appendTo(formContainer);
            this.submitNode.appendTo(this.submitContainerNode);
            this.inputNode.appendTo(this.inputContainerNode);
            this.inputContainerNode.appendTo(this.formNode);
            this.submitContainerNode.appendTo(this.formNode);
            this.formNode.on('submit', this.submit);
            this.formNode.focus();

            this.generated = true;
        },
        submit: function(e) {
            e.preventDefault();

            var value = that.form.inputNode.val();

            if(value.length == 0) {
                return false;
            }

            that.send(value);

            that.form.inputNode.val('');

            return false;
        }
    };

    this.send = function(value, type, data, id, key) {
        value = value || '';
        type = type || 'u';
        data = data || {};
        id = id || chatUser.id;
        key = key || chatUser.key;

        var message = { message: value, type: type, id: id, key: key,  data: data };

        for(var plugin in that.plugins) {
            try {
                that.plugins[plugin].onSend(message);
            } catch(e) {}
        }

        that.websocket.send(JSON.stringify(message));
    };

    this.sendMessage = function(value) {
        var message = {
            message: value,
            type: 'u',
            id: chatUser.id,
            key: chatUser.key,
            data: {}
        };

        for(var plugin in that.plugins) {
            try {
                that.plugins[plugin].onSend(message);
            } catch(e) {}
        }

        that.websocket.send(JSON.stringify(message));
    };


    this.close = function() {

    };

    this.onOpen = function(ev) {
        for(var plugin in that.plugins) {
            try {
                that.plugins[plugin].onInit();
            } catch(e) {}
        }

        that.printMessage({
            type: 's',
            message: 'Połączono z serwerem'
        });

/*        that.updateTimer = setInterval(function() {
            that.send('', 'u');
        }, 10000);*/

 /*               that.updateTimer = setInterval(function() {
                    console.log('Updating time');
                    that.send('time', 't');
                }, 30000);*/

        connectionFailed = false;
    };

    this.onMessage = function(ev) {
        var msg = JSON.parse(ev.data);

        if(msg.name) {
            msg.name = JSON.parse(msg.name);
        }

        that.printMessage(msg);
    };

    this.onError = function(ev) {

    };

    this.onClose = function(ev) {
        if(!connectionFailed) {
            clearInterval(that.updateTimer);
            that.printMessage({
                type: 's',
                message: 'Utracono połączenie z serwerem.'
            });
            connectionFailed = true;
        }

        that.init();
    };

    this.attachPlugin = function(plugin) {
        this.plugins.push(plugin);

        try {
            plugin.pluginAttached(this);
        } catch(e) {}
    };

    this.hasPrivileges = function(plugin, action) {
        for(var i in this.privileges) {
            var item = this.privileges[i];
            if(item.plugin == '*' || item.plugin == plugin) {
                if(item.action == '*' || item.action == action) {
                    return true;
                }
            }
        }

        return false;
    };

    this.setSettings = function(values) {
        var settings = [];

        $.each(values, function(index, value) {
            settings[value.name] = value.value;
        });

        //console.log(settings);

        this.settings = settings;

        $.each(this.plugins, function(index, plugin) {
            try {
                plugin.onSettingsChange();
            } catch(e) {}
        });
    };

    this.printMessage = function(msg) {
        var messageNode = false;

        for(var plugin in this.plugins) {
            try {
                msg = this.plugins[plugin].onMessageReceived(msg);
            } catch(e) {}
        }

        //console.log(msg);
        if(msg.type == 'u') {
            var classes = 'message message-user';
            if(msg.private) {
                classes += ' message-private';
            }
            if($('#msg-' + msg.id).length) {
                return false;
            }
            messageNode =
                $('<p />', {class: classes, id: 'msg-' + msg.id}).append(
                    $('<time />').text(msg.time)
                ).append(
                    $('<strong />', {class: 'user-name'}).text(msg.user.name)
                ).append(
                    $('<span />', {class: 'message'}).text(msg.message)
                ).data('user-name', msg.user.name);

        } else if(msg.type == 's'  && msg.message != undefined) {
            messageNode =
                $('<p />', {class: 'message message-system'}).prepend(
                    $('<span />').text(msg.message)
                );
        } else if(msg.type == 'p') {
            for(var plugin in this.plugins) {
                try {
                    this.plugins[plugin].onPluginAction(msg);
                } catch(e) {}
            }
        } else if(msg.type == 't') {
            this.time = parseInt(msg.message);

            clearInterval(this.timeCounter);
            this.timeCounter = setInterval(function() {
                that.time = that.time + 1;
            }, 1000);
        }

        if(messageNode) {
            for(var plugin in this.plugins) {
                try {
                    messageNode = this.plugins[plugin].messageFilter(msg, messageNode);
                } catch(e) {}
            }

            console.log(messageNode.find('span').html());

            container.prepend(messageNode);
        }

    };
}

function ChatPlugin() {
    this.messageFilter = function(messageNode) {};
    this.messageReceived = function(msg) {};
    this.onMessageReceived = function(msg) {};
    this.onSend = function(message) {};
    this.onInit = function() {};
    this.onPluginAction = function(message) {};
    this.onPrivilegesUpdate = function() {};
    this.onSettingsChange = function() {};
}

function ChatPanel(content) {
    var overlay = $('<div />', {class: 'chat-overlay'}).hide();
    var panel = $('<div />', {class: 'chat-panel'}).append(content);
    var that = this;

    panel.on('click', function(e) {
        e.preventDefault();

        return false;
    });

    overlay.append(panel).click(function() {
        that.close();
    });

    $('body').append(overlay);
    overlay.fadeIn();

    this.close = function() {
        overlay.fadeOut('fast', function() {
            overlay.remove();
        });
    };

    this.setContent = function(content) {
        panel.html(content)
    };
}

export {Chat, ChatPanel, ChatPlugin};
