import {ChatPlugin} from '../Chat';

/*
class ChatPluginRefresh extends ChatPlugin
{
    chat = null;

    pluginAttached = function(chat) {
        this.chat = chat;
    };

    onPluginAction = function(message) {
        if (message.type === 'p') {
            let msg = JSON.parse(message.message);

            if (msg.plugin === 'refresh') {
                location.reload();
            }
        }
    };
}
*/

function ChatPluginRefresh() {
    this.chat = null;

    var that = this;

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.onPluginAction = function(message) {
        if(message.type == 'p') {
            var msg = JSON.parse(message.message);

            if(msg.plugin == 'refresh') {
                location.reload();
            }
        }
    };
}
ChatPluginRefresh.prototype = ChatPlugin;

export {ChatPluginRefresh}
