import {ChatPlugin} from '../Chat';

function ChatPluginFullscreen(messagesNode) {
    this.chat = null;
    var that = this;


    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.onSettingsChange = function() {
        if(that.chat.settings['fullscreen'] != 'true' && that.chat.settings['fullscreen'] != true) {
            $('body > header').slideDown();
        } else {
            $('body > header').slideUp();
        }
    };


}
ChatPluginFullscreen.prototype = ChatPlugin;

export {ChatPluginFullscreen}
