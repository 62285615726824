import {ChatPlugin, ChatPanel} from '../Chat';

function ChatPluginPuppet(formPanelNode) {
    this.users = [];
    this.chat = null;

    var that = this;
    var puppet;
    var puppetChecked = false;
    var puppetSelected = false;

    this.pluginAttached = function(chat) {
        this.chat = chat;


    };

    this.onInit = function() {
        puppetChecked = false;

        that.askForPuppet();
    };

    this.askForPuppet = function() {
        if(puppetChecked == true) {
            return;
        }
        //console.log('Asking for puppet');

        that.chat.send('puppet', 'p');

        setTimeout(that.askForPuppet, 300);
    };

    this.onPluginAction = function(message) {
        if(message.type == 'p') {
            var msg = JSON.parse(message.message);

            if(msg.plugin == 'puppet') {
                if(msg.puppet != undefined) {
                    puppetChecked = true;
                    if(puppet != undefined && puppet.name != msg.puppet.name) {
                        that.removePuppet();
                        puppet = undefined;
                    }

                    if(puppet == undefined && msg.puppet != false) {
                        puppet = msg.puppet;
                        that.setPuppet();
                    }
                } else if(msg.puppets != undefined) {

                    var table = $('<table />', {class: 'chat-table-puppets'});
                    table.append(
                        $('<tr />')
                                .append( $('<th />').text('Nick') )
                                .append( $('<th />').text('Nazwa') )
                                .append( $('<th />').text('Data przyznania') )
                                .append( $('<th />').text('Avatar') )
                                .append( $('<th />').text('Przyznał') )
                                .append( $('<th />').text(''))
                    );

                    $.each(msg.puppets, function(index, element) {
                        var avatar = '';
                        if(element.avatar != undefined && element.avatar.length > 2) {
                            avatar = $('<img />', {src: element.avatar});
                        }
                        var row = $('<tr />', {'data-name': element.jbname}).append(
                            $('<td />').text(element.jbname)
                        ).append(
                            $('<td />').text(element.name)
                        ).append(
                            $('<td />').text( (element.granted_date).split(' ')[0] )
                        ).append(
                            $('<td />').html( avatar )
                        ).append(
                            $('<td />').text(element.granted_name)
                        ).append(
                            $('<td />', {class: 'chat-panel-action'}).text('×').on('click', function() {
                                if (window.confirm('Na pewno skasować postać ogniskową ' + element.name + '?')) {
                                    that.chat.send('$puppet remove ' + element.jbname);
                                    row.remove();
                                }
                            })
                        );

                        row.appendTo(table);
                    });
                    new ChatPanel(table);
                }

            }
        }
    };

    this.removePuppet = function() {
        if($('#chat-form-puppet-container').length > 0) {
            $('#chat-form-puppet-container').remove();
        }
    };

    this.setPuppet = function() {
        if(puppet.name != undefined && $('#chat-form-puppet-container').length == 0) {
            puppetSelected = false;
            var button =
                $('<div />', {id: 'chat-form-puppet'})
                    .text(puppet.name)
                    .on('click', function() {
                        puppetSelected = !puppetSelected;
                        $(this).toggleClass('selected')
                    });
            var buttonContainer = $('<div />', {id: 'chat-form-puppet-container'}).append(button);
            formPanelNode.addClass('chat-form-panel-with-puppet').append(buttonContainer);
        }
    };

    this.messageFilter = function(msg, messageNode) {
        return messageNode;
    };

    this.onSend = function(message) {
        message.data.puppet = puppetSelected;
    };


    this.onPrivilegesUpdate = function() {
        if(!that.chat.hasPrivileges('puppet', 'display')) {
            return false;
        }

        if($('#chat-form-puppets').length) {
            return false;
        }

        var config = $('#chat-form-config');

        if(config.length == 0) {
            return false;
        }

        var puppets = $('<li />', {id: 'chat-form-puppets'}).text('Postacie ogniskowe').on('click', function() {
            that.chat.send('$puppet display');
        }).appendTo(config.children('ul'));

    }

}
ChatPluginPuppet.prototype = ChatPlugin;

export {ChatPluginPuppet}
