import {ChatPlugin} from '../Chat';

function ChatPluginPresent(formPanelNode) {
    this.chat = null;

    var that = this;

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.onPrivilegesUpdate = function() {
        if(!that.chat.hasPrivileges('present', 'display')) {
            return false;
        }

        if($('#chat-form-present').length) {
            return false;
        }

        var config = $('#chat-form-config');

        if(config.length == 0) {
            return false;
        }

        var button = $('<li />', {id: 'chat-form-present'}).text('Obecni').on('click', function() {
            that.chat.send('$present display');
        }).appendTo(config.children('ul'));
    };

    this.messageFilter = function(msg, messageNode) {
        return messageNode;
    };
}
ChatPluginPresent.prototype = ChatPlugin;

export {ChatPluginPresent}
