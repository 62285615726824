import {ChatPlugin} from '../Chat';

function ChatPluginConsole() {
    this.chat = null;

    var that = this;

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.onPluginAction = function(message) {
        if(message.type != 'p') {
            return false;
        }

        var msg = JSON.parse(message.message);

        if(msg.plugin == undefined || msg.plugin != 'console') {
            return false;
        }

        if(msg.list != undefined) {
            var table = $('<table />', {class: 'chat-table-console'});
            table.append(
                $('<tr />')
                    .append( $('<th />').text('Kto') )
                    .append( $('<th />').text('Plugin') )
                    .append( $('<th />').text('Akcja') )
                    .append( $('<th />').text('Przez kogo') )
                    .append( $('<th />').text('Kiedy') )
                    .append( $('<th />').text(''))
            );

            $.each(msg.list, function(index, element) {
                var row = $('<tr />', {'data-name': element.user}).append(
                    $('<td />').text(element.user_name)
                ).append(
                    $('<td />').text(element.plugin)
                ).append(
                    $('<td />').text(element.action)
                ).append(
                    $('<td />').text(element.from_name)
                ).append(
                    $('<td />').text( (element.granted_time).split(' ')[0] )
                ).append(
                    $('<td />', {class: 'chat-panel-action'}).text('×').on('click', function() {
                        if (window.confirm('Na pewno skasować usunąć te uprawnienia użytkownika ' + element.user_name + '?')) {
                            that.chat.send('$console revoke ' + element.user_name + ' ' + element.plugin + ' ' + element.action);
                            row.remove();
                        }
                    })
                );

                row.appendTo(table);
            });
            new ChatPanel(table);
        }
    };

    this.onPrivilegesUpdate = function() {
        if(!that.chat.hasPrivileges('console', 'display')) {
            return false;
        }

        if($('#chat-form-console').length) {
            return false;
        }

        var config = $('#chat-form-config');

        if(config.length == 0) {
            return false;
        }

        $('<li />', {id: 'chat-form-console'}).text('Uprawnienia').on('click', function() {
            that.chat.send('$console display');
        }).appendTo(config.children('ul'));
    };
}
ChatPluginConsole.prototype = ChatPlugin;

export {ChatPluginConsole}
