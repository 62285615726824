import {ChatPlugin, ChatPanel} from '../Chat';

function ChatPluginSettings(formPanelNode, messagesNode) {
    this.users = [];
    this.chat = null;

    var that = this;
    var settingsChecked = false;

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.onInit = function() {
        settingsChecked = false;

        that.askForSettings();
    };

    this.askForSettings = function() {
        if(settingsChecked == true) {
            return;
        }

        that.chat.send('settings', 'p');

        setTimeout(that.askForSettings, 300);
    };

    this.onPluginAction = function(message) {
        if(message.type == 'p') {
            var msg = JSON.parse(message.message);

            if(msg.plugin == 'settings') {
                settingsChecked = true;

                that.chat.setSettings(JSON.parse(msg.value));
                that.setPanel();
            }
        }
    };

    this.setPanel = function() {
        var config = $('#chat-form-config');

        if(config.length == 0 || config.find('#chat-form-settings').length > 0) {
            return false;
        }

        $('<li />', {id: 'chat-form-settings'}).text('Ustawienia').on('click', function() {
            that.setForm();
        }).appendTo(config.find('ul'));
    };

    this.setForm = function() {
        var panel = new ChatPanel();
        var form = $('<form />');

        $('<div />')
                .append( $('<label />', {for: 'settings-ignore'}).text('Ignoruj') )
                .append( $('<textarea />', {name: 'ignore', id: 'settings-ignore'}).text(that.chat.settings['ignore']) )
                .append( $('<span />').text('Nicki użytkowników wypisane po przecinku.') )
        .appendTo(form) ;

        $('<div />')
            .append( $('<label />', {for: 'settings-highlight'}).text('Podświetl') )
            .append( $('<textarea />', {name: 'highlight', id: 'settings-highlight'}).text(that.chat.settings['highlight']) )
            .append( $('<span />').text('Słowa lub frazy wypisane po przecinku.') )
            .appendTo(form) ;

        $('<div />')
            .append( $('<label />', {for: 'settings-seconds'}).text('Sekundnik') )
            .append( $('<select />', {name: 'seconds', id: 'settings-seconds'}).append(
                $('<option />', {value: 'false', selected: that.chat.settings['seconds'] == 'false'}).text('Nie')
            ).append(
                $('<option />', {value: 'true', selected: that.chat.settings['seconds'] == 'true'}).text('Tak')
            ) )
            .append( $('<span />').text('Czas wypowiedzi z dokładnością do sekundy.') )
            .appendTo(form) ;


        $('<div />')
            .append( $('<label />', {for: 'settings-theme'}).text('Wybierz styl') )
            .append( $('<select />', {name: 'theme', id: 'settings-theme'}).append(
                $('<option />', {value: 'classic', selected: that.chat.settings['theme'] == 'classic'}).text('Klasyczne')
            ).append(
                $('<option />', {value: 'office', selected: that.chat.settings['theme'] == 'office'}).text('Biurowe')
            ).append(
                $('<option />', {value: 'lava', selected: that.chat.settings['theme'] == 'lava'}).text('Lawa')
            ).append(
                $('<option />', {value: 'unicorns', selected: that.chat.settings['theme'] == 'unicorns'}).text('Jednorożce')
            ) )
            .appendTo(form) ;

        $('<div />')
            .append( $('<label />', {for: 'settings-fullscreen'}).text('Pełny ekran') )
            .append( $('<select />', {name: 'fullscreen', id: 'settings-fullscreen'}).append(
                $('<option />', {value: 'false', selected: that.chat.settings['fullscreen'] == 'false'}).text('Nie')
            ).append(
                $('<option />', {value: 'true', selected: that.chat.settings['fullscreen'] == 'true'}).text('Tak')
            ) )
            .append( $('<span />').text('Ukrywa nagłówek strony.') )
            .appendTo(form) ;


        $('<span />', {class: 'chat-panel-submit'}).text('Zapisz').on('click', function() {
            that.chat.send('$settings set ' + JSON.stringify(form.serializeArray()));
            that.chat.setSettings(form.serializeArray());
            panel.close();
        }).appendTo(form);

        panel.setContent(form);


    };

    this.onMessageReceived = function(msg) {

        return msg;
    };

    this.messageFilter = function(msg, messageNode) {
        return messageNode;
    };

    this.onSettingsChange = function() {
        if(that.chat.settings['seconds'] != 'true' && that.chat.settings['seconds'] != true) {
            return;
        }

        messagesNode.find('.message-user').each(function() {
            //console.log($(this).find('time').text());
        });
    };

}
ChatPluginSettings.prototype = ChatPlugin;

export {ChatPluginSettings}
