import {ChatPlugin} from '../Chat';

function ChatPluginTheme(formPanelNode) {
    this.chat = null;

    var that = this;

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.onInit = function() {

    };

    this.messageFilter = function(msg, messageNode) {

        return messageNode;
    };

    this.onSend = function(message) {

    };

    this.onSettingsChange = function() {
        var $body = $("body");
        $body.removeClass (function (index, css) {
            return (css.match (/(^|\s)theme-\S+/g) || []).join(' ');
        });

        if(that.chat.settings['theme'] == 'office') {
            $body.addClass('theme-office');
        } else if(that.chat.settings['theme'] == 'lava') {
            $body.addClass('theme-lava');
        } else if(that.chat.settings['theme'] == 'unicorns') {
            $body.addClass('theme-unicorns');
        }
    };
}

ChatPluginTheme.prototype = ChatPlugin;

export {ChatPluginTheme}
