import {ChatPlugin} from '../Chat';

function ChatPluginRename(messagesNode, userListNode) {
    this.chat = null;
    this.names = {};

    var that = this;
    var renameChecked = false;

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.onInit = function() {
        renameChecked = false;

        that.askForSettings();
    };

    this.askForSettings = function() {
        if(renameChecked == true) {
            return;
        }

        that.chat.send('rename', 'p');

        setTimeout(that.askForSettings, 300);
    };

    this.messageFilter = function(msg, messageNode) {
        this.onPrivilegesUpdate();

        var $name = messageNode.find('.user-name');

        $name.attr('data-user', $name.text());

        $.each(this.names, function(key, user) {
            if($name.text() == key) {
                $name.text(user[0]);
            }
        });

        if(msg.type == 't') {
            this.renameUsers();
        }

        return messageNode;
    };

    this.onPrivilegesUpdate = function() {
        if(this.chat.hasPrivileges('rename', 'user')) {

            userListNode.find('.chat-user').each(function() {
                var userName = $(this).data('user-name');

                if($(this).find('.chat-user-action-rename').length) {
                    return;
                }

                var options = $('<form />')
                    .append($('<input />', {type: 'text', placeholder: 'nowy pseudonim'}))
                    .append($('<input />', {type: 'text', placeholder: 'czas'}))
                    .append($('<input />', {type: 'submit', value: 'Transmutuj'}))
                    .click(function(e) {
                        e.stopPropagation();
                    })
                    .submit(function(e) {
                        e.preventDefault();
                        that.chat.send('$rename user "' + userName + '" "' + $(this).find('input:first-of-type').val() + '" ' + $(this).find('input:nth-last-of-type(2)').val());
                        $(this).fadeOut(400, function() {
                            $(this).find('input:first-of-type').val('');
                            $(this).find('input:nth-last-of-type(2)').val('');
                        });
                        return false;
                    });

                var button = $('<div />', {class: 'chat-user-action-rename'}).html('<span class="icon-quill"></span>').append(options);
                button.on('click', function(e) {
                    options.fadeToggle(400, function() {
                        $(this).find('input:first-of-type').focus();
                    });
                    e.stopPropagation();
                });

                $(this).find('.chat-user-actions').append(button);
            });

        }
    };

    this.onPluginAction = function(message) {
        if(message.type != 'p') {
            return false;
        }

        var msg = JSON.parse(message.message);

        if(msg.plugin == undefined || msg.plugin != 'rename') {
            return false;
        }

        renameChecked = true;

        this.names = msg.list;

        console.log('renaming users');

        this.renameUsers();
    };

    this.renameUsers = function() {
        messagesNode.find('.message-user').each(function() {
            var $userName = $(this).find('.user-name');
            $userName.text($userName.attr('data-user'));
            $.each(that.names, function(key, user) {
                if($userName.attr('data-user') == key) {
                    $userName.text(user[0]);
                }
            });
        });

        userListNode.find('.chat-user').each(function() {
            var name = $(this).attr('data-user-name')
            var $userName = $(this).find('a');
            $userName.text(name);
            $.each(that.names, function(key, user) {
                if(name == key) {
                    $userName.text(user[0]);
                }
            });
        });

    };
}
ChatPluginRename.prototype = ChatPlugin;

export {ChatPluginRename}
