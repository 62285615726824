import {ChatPlugin, ChatPanel} from '../Chat';

function ChatPluginBan(userListNode) {
    this.chat = null;

    this.pluginAttached = function (chat) {
        this.chat = chat;
    };

    var that = this;

    this.messageFilter = function (msg, messageNode) {
        this.onPrivilegesUpdate();
        return messageNode;
    };

    this.onPrivilegesUpdate = function () {
        if (this.chat.hasPrivileges('ban', 'user')) {
            userListNode.find('.chat-user').each(function () {
                var userName = $(this).data('user-name');

                if ($(this).find('.chat-user-action-ban').length) {
                    return;
                }

                var options = $('<ul />')
                    .append($('<li />').attr('data-time', 60).text('minuta'))
                    .append($('<li />').attr('data-time', 300).text('pięć minut'))
                    .append($('<li />').attr('data-time', 600).text('dziesięć minut'))
                    .append($('<li />').attr('data-time', 1800).text('pół godziny'))
                    .append($('<li />').attr('data-time', 3600).text('godzina'))
                    .append($('<li />').attr('data-time', 3600 * 6).text('sześć godzin'))
                    .append($('<li />').attr('data-time', 3600 * 12).text('pół dnia'))
                    .append($('<li />').attr('data-time', 3600 * 24).text('dzień'))
                    .append($('<li />').attr('data-time', 3600 * 24 * 7).text('tydzień'))
                    .append($('<li />').attr('data-time', 3600 * 24 * 30).text('miesiąc'))
                    .append($('<li />').attr('data-time', 3600 * 24 * 365).text('rok'));

                var button = $('<div />', {class: 'chat-user-action-ban'}).html('<span class="icon-evil"></span>').append(options);
                button.on('click', function () {
                    options.slideToggle();
                }).on('mouseleave', function () {
                    options.slideUp();
                });

                options.find('li').on('click', function () {
                    var time = $(this).attr('data-time');
                    that.chat.send('$ban user "' + userName + '" ' + time);
                });


                $(this).find('.chat-user-actions').append(button);
            });

            if (!that.chat.hasPrivileges('ban', 'display')) {
                return false;
            }

            if ($('#chat-form-bans').length) {
                return false;
            }

            var config = $('#chat-form-config');

            if (config.length === 0) {
                return false;
            }

            $('<li />', {id: 'chat-form-bans'}).text('Packi').on('click', function () {
                that.chat.send('$ban display');
            }).appendTo(config.children('ul'));
        }
    }

    this.onPluginAction = function (message) {
        if (message.type !== 'p') {
            return false;
        }

        var msg = JSON.parse(message.message);

        if (msg.plugin === undefined || msg.plugin !== 'ban') {
            return false;
        }

        if (msg.list !== undefined) {
            var table = $('<table />', {class: 'chat-table-bans'});
            table.append(
                $('<tr />')
                    .append($('<th />').text('Kto'))
                    .append($('<th />').text('Do kiedy'))
                    .append($('<th />').text('Przez kogo'))
                    .append($('<th />').text('Kiedy'))
                    .append($('<th />').text(''))
            );

            $.each(msg.list, function (index, element) {
                var row = $('<tr />', {'data-name': element.user}).append(
                    $('<td />').text(element.user)
                ).append(
                    $('<td />').text(element.ends)
                ).append(
                    $('<td />').text(element.from_name)
                ).append(
                    $('<td />').text((element.when).split(' ')[0])
                ).append(
                    $('<td />', {class: 'chat-panel-action'}).text('×').on('click', function () {
                        if (window.confirm('Na pewno skasować cofnąć ban ' + element.user + '?')) {
                            that.chat.send('$ban remove ' + element.user);
                            row.remove();
                        }
                    })
                );

                row.appendTo(table);
            });
            new ChatPanel(table);
        }
    }
}

ChatPluginBan.prototype = ChatPlugin;

export {ChatPluginBan}
