import {ChatPlugin, ChatPanel} from '../Chat';

function ChatPluginAuth(formPanelNode) {
    this.chat = null;

    var that = this;
    var authPerformed = false;

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.onInit = function() {
        that.performAuthorization();
    };

    this.performAuthorization = function() {
        if(authPerformed == true) {
            return;
        }
        ///console.log('Sending authorization.');

        that.chat.send('auth', 'p', {auth: true});

        setTimeout(that.performAuthorization, 300);
    };

    this.onPluginAction = function(message) {
        if(message.type != 'p') {
            return false;
        }

        var msg = JSON.parse(message.message);

        if(msg.plugin != 'auth') {
            return false;
        }

        authPerformed = true;

        that.chat.privileges = msg.privileges;

        //console.log(message.privileges);

        for(var plugin in that.chat.plugins) {
            try {
                messageNode = that.chat.plugins[plugin].onPrivilegesUpdate();
            } catch(e) {}
        }
    };

    this.onPrivilegesUpdate = function() {
        if($('#chat-form-config-container').length == 0) {
            var configContainer = $('<div />', {id: 'chat-form-config-container'});
            var config = $('<div />', {id: 'chat-form-config'}).html('<span class="icon-cog"></span>').on('click', function() {
                $(this).children('ul').fadeToggle();
            }).append(
                $('<ul />')
            );
            configContainer.append(config).appendTo(formPanelNode.find('form'));
        }

    }




}
ChatPluginAuth.prototype = ChatPlugin;

export {ChatPluginAuth}
