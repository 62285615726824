import {ChatPlugin} from '../Chat';

function ChatPluginMe() {
    this.messageFilter = function(msg, messageNode) {
        if(messageNode.hasClass('message') && msg.me == true) {
            messageNode.addClass('message-me');
        }
        return messageNode;
    }
}
ChatPluginMe.prototype = ChatPlugin;

export {ChatPluginMe}
