import {ChatPlugin} from '../Chat';

function ChatPluginDice(formPanelNode) {
    this.chat = null;

    var that = this;
    var privateTo = null

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.onInit = function() {
        if($('#chat-form-dice-container').length) {
            return false;
        }

        var dicePanel = $('<ul />', {id: 'chat-form-dice-panel'})
            .append($('<li />').text(2))
            .append($('<li />').text(3))
            .append($('<li />').text(6))
            .append($('<li />').text(10))
            .append($('<li />').text(12))
            .append($('<li />').text(69))
            .append($('<li />').text(100))
            .append($('<li />').text(1000))
            .hide();
        var diceContainer = $('<div />', {id: 'chat-form-dice-container'});
        var diceButton = $('<div />', {id: 'chat-form-dice'}).html('<span class="icon-dice"></span>').on('click', function() {
            $('#chat-form-dice-panel').fadeToggle();
        });
        diceContainer.append(diceButton).append(dicePanel).appendTo(formPanelNode.find('form'));

        dicePanel.find('li').on('click', function() {
            that.chat.sendMessage('/roll ' + $(this).text());
            that.hidePanel();
        });
    };

    this.messageFilter = function(msg, messageNode) {

        return messageNode;
    };

    this.onSend = function(message) {

    };

    this.hidePanel = function() {
        $('#chat-form-dice-panel').hide();
    };




}
ChatPluginDice.prototype = ChatPlugin;

export {ChatPluginDice}
