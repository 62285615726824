import {ChatPlugin} from '../Chat';

function ChatPluginPrivateMessage(userListNode, formPanelNode) {
    this.users = [];
    this.chat = null;

    var that = this;
    var privateTo = null

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.onInit = function() {
        this.refreshButtons();

        if($('#chat-form-private-container').length) {
            return;
        }

        var privateContainer = $('<div />', {id: 'chat-form-private-container'}).text('').hide();
        formPanelNode.find('form').prepend(privateContainer);

        privateContainer.on('click', function() {
            that.unsetPrivate();
        });

        that.chat.form.inputNode.on('keydown', function(e) {
            if(e.keyCode == 27) {
                that.unsetPrivate();
            }
        });
    };

    this.messageFilter = function(msg, messageNode) {
        this.refreshButtons();

        return messageNode;
    };

    this.onSend = function(message) {

        if(privateTo != null) {
            message.data.private = privateTo;
        } else {
            var tmp = message.message.split('');

            if(tmp[0] == '>') {
                var nick = '';
                var i = 1;
                for(i = 1; i < tmp.length; i++ ) {
                    if(tmp[i] == ':') {
                        break;
                    }

                    nick = nick + tmp[i];
                }
                message.message = message.message.substr(i+1, tmp.length);
                message.data.private = nick;

                if(nick.length > 2) {
                    that.setPrivate(nick);
                }
            }
        }


    };

    this.refreshButtons = function() {
        userListNode.find('.chat-user').each(function() {
            var userName = $(this).data('user-name');

            if($(this).find('.chat-user-action-priv').length) {
                return;
            }

            var button = $('<div />', {class: 'chat-user-action-priv'}).html('<span class="icon-envelop"></span>');
            button.on('click', function() {
                if(privateTo == null || userName != $('#chat-form-private-container').text()) {
                    that.setPrivate(userName);
                } else {
                    that.unsetPrivate();
                }
            });

            $(this).find('.chat-user-actions').append(button);
        });
    };

    this.setPrivate = function(name) {
        privateTo = name;

        $('#chat-form-private-container').text(name).show();
        $('#chat-form-message').focus();
    };

    this.unsetPrivate = function() {
        privateTo = null;

        $('#chat-form-private-container').hide();
        $('#chat-form-message').focus();
    };
}
ChatPluginPrivateMessage.prototype = ChatPlugin;

export {ChatPluginPrivateMessage}
