import {ChatPlugin} from '../Chat';

function ChatPluginIgnore(messagesNode) {
    this.chat = null;
    this.ignore = [];
    var that = this;

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.messageFilter = function(msg, messageNode) {
        if(this.ignore.indexOf(msg.user.name) >= 0) {
            messageNode.hide();
        }
        return messageNode;
    };

    this.onSettingsChange = function() {
        var ignore = [];

        $.each(that.chat.settings['ignore'].split(','), function() {
            ignore.push($.trim(this));
        });

        this.ignore = ignore;

        messagesNode.find('.message-user').each(function() {
            if(that.ignore.indexOf($(this).find('strong').text()) >= 0) {
                $(this).hide();
            } else {
                $(this).show();
            }
        });
    };
}
ChatPluginIgnore.prototype = ChatPlugin;

export {ChatPluginIgnore}
