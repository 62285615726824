import {ChatPlugin, ChatPanel} from '../Chat';

function ChatPluginColors() {
    this.chat = null;

    var that = this;

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.messageFilter = function(msg, messageNode) {
        if(messageNode.hasClass('message-user') && msg.color.length > 0) {
            messageNode.css('color', msg.color);
        }
        return messageNode;
    };

    this.onPluginAction = function(message) {
        if(message.type != 'p') {
            return false;
        }

        var msg = JSON.parse(message.message);

        if(msg.plugin == undefined || msg.plugin != 'color') {
            return false;
        }

        if(msg.list != undefined) {
            var table = $('<table />', {class: 'chat-table-colors'});
            table.append(
                $('<tr />')
                    .append( $('<th />').text('Kto') )
                    .append( $('<th />').text('Kolor') )
                    .append( $('<th />').text('Przez kogo') )
                    .append( $('<th />').text('Kiedy') )
                    .append( $('<th />').text(''))
            );

            $.each(msg.list, function(index, element) {
                var row = $('<tr />', {'data-name': element.user}).append(
                    $('<td />').text(element.user)
                ).append(
                    $('<td />').text(element.color)
                ).append(
                    $('<td />').text(element.from_name)
                ).append(
                    $('<td />').text( (element.when).split(' ')[0] )
                ).append(
                    $('<td />', {class: 'chat-panel-action'}).text('×').on('click', function() {
                        if (window.confirm('Na pewno skasować usunąć kolor użytkownika ' + element.user + '?')) {
                            that.chat.send('$color remove ' + element.user);
                            row.remove();
                        }
                    })
                );

                row.appendTo(table);
            });
            new ChatPanel(table);
        }
    };

    this.onPrivilegesUpdate = function() {
        if(!that.chat.hasPrivileges('color', 'display')) {
            return false;
        }

        if($('#chat-form-color').length) {
            return false;
        }

        var config = $('#chat-form-config');

        if(config.length == 0) {
            return false;
        }

        $('<li />', {id: 'chat-form-color'}).text('Kolory').on('click', function() {
            that.chat.send('$color display');
        }).appendTo(config.children('ul'));
    };
}
ChatPluginColors.prototype = ChatPlugin;

export {ChatPluginColors}
