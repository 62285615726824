import $ from "jquery";
import 'lightbox2';
import '../../global/js/cms.js';
import {Chat} from './Chat/Chat';
import {ChatPluginAuth} from "./Chat/plugins/Auth";
import {ChatPluginBan} from "./Chat/plugins/Ban";
import {ChatPluginColors} from "./Chat/plugins/Colors";
import {ChatPluginConsole} from "./Chat/plugins/Console";
import {ChatPluginDice} from './Chat/plugins/Dice';
import {ChatPluginFullscreen} from "./Chat/plugins/Fullsceen";
import {ChatPluginHighlight} from './Chat/plugins/Highlight';
import {ChatPluginIgnore} from './Chat/plugins/Ignore';
import {ChatPluginMe} from './Chat/plugins/Me';
import {ChatPluginNotification} from './Chat/plugins/Notification';
import {ChatPluginPhrases} from "./Chat/plugins/Phrases";
import {ChatPluginPresent} from "./Chat/plugins/Present";
import {ChatPluginPrivateMessage} from './Chat/plugins/PrivateMessage';
import {ChatPluginPuppet} from './Chat/plugins/Puppet';
import {ChatPluginRefresh} from "./Chat/plugins/Refresh";
import {ChatPluginRename} from './Chat/plugins/Rename';
import {ChatPluginSettings} from "./Chat/plugins/Settings";
import {ChatPluginTheme} from "./Chat/plugins/Theme";
import {ChatPluginTime} from "./Chat/plugins/Time";
import {ChatPluginUrlize} from './Chat/plugins/Urlize';
import {ChatPluginUserList} from "./Chat/plugins/Userlist";

window.jQuery = window.$ = require("jquery");

$(document).ready(function(){
    var chat = new Chat($('#chat-messages'), $('#chat-form-panel'));
    chat.attachPlugin(new ChatPluginAuth($('#chat-form-panel')));
    chat.attachPlugin(new ChatPluginSettings($('#chat-form-panel'), $('#chat-messages')));

    chat.attachPlugin(new ChatPluginPhrases());
    chat.attachPlugin(new ChatPluginColors());
    chat.attachPlugin(new ChatPluginMe());
    chat.attachPlugin(new ChatPluginUserList($('#chat-users-list')));
    chat.attachPlugin(new ChatPluginPuppet($('#chat-form-panel')));
    chat.attachPlugin(new ChatPluginConsole());

    //

    chat.attachPlugin(new ChatPluginUrlize());
    chat.attachPlugin(new ChatPluginNotification());
    chat.attachPlugin(new ChatPluginPrivateMessage($('#chat-users-list'), $('#chat-form-panel')));
    chat.attachPlugin(new ChatPluginRename($('#chat-messages'), $('#chat-users-list')));
    chat.attachPlugin(new ChatPluginBan($('#chat-users-list')));
    chat.attachPlugin(new ChatPluginDice($('#chat-form-panel')));
    chat.attachPlugin(new ChatPluginIgnore($('#chat-messages')));
    chat.attachPlugin(new ChatPluginTheme($('#chat-messages')));
    chat.attachPlugin(new ChatPluginRefresh());

    chat.attachPlugin(new ChatPluginHighlight($('#chat-messages')));
    chat.attachPlugin(new ChatPluginTime($('#chat-messages')));
    chat.attachPlugin(new ChatPluginPresent());

    chat.attachPlugin(new ChatPluginFullscreen());

    chat.port = port;
    chat.init();

    $( window ).on('beforeunload', function() {
        chat.close();
    });
});
