import {ChatPlugin} from '../Chat';

function ChatPluginUserList(userListNode) {
    this.users = [];
    this.chat = null;

    var howLong = 60*5;
    var that = this;

    this.pluginAttached = function(chat) {
        this.chat = chat;
    };

    this.messageFilter = function(msg, messageNode) {
        if(msg.user != undefined && msg.user.name != undefined && msg.user.name.length > 0 && msg.type == 'u') {
            this.addUser(msg);
        }

        return messageNode;
    };

    this.addUser = function(msg) {
        this.users[msg.user.name] = {
            id: msg.user.id,
            name: msg.user.name,
            lastActivity: msg.timestamp,
            avatar: null
        };

        if(msg.user.avatar != undefined) {
            this.users[msg.user.name].avatar = msg.user.avatar;
        }

        //console.log(msg);

        if(msg.timestamp >= that.chat.time - howLong && that.chat.time > 100 && msg.private == false) {
            if(!$('div[data-user-name="' + msg.user.name + '"]').length) {
                var nick, avatar;
                if(msg.user.id > 0) {
                    nick = $('<a />', {href: 'http://jaskiniowcy.heroes.net.pl/mieszkaniec/' + msg.user.id}).text(msg.user.name);
                } else {
                    nick = $('<span />').text(msg.user.name);
                }

                if(msg.user.avatar != null) {
                    avatar = $('<img />', {src: msg.user.avatar});
                } else {
                    if(msg.user.id > 0) {
                        avatar = $('<img />', {src: 'https://pliki.jaskiniabehemota.net/avatars/users/' + msg.user.id});
                    } else {
                        avatar = '';
                    }
                }

                var element = $('<div />', {class: 'chat-user'}).append(nick).append(avatar).append($('<div />', {class: 'chat-user-actions'}));

                element.attr('data-user-name', msg.user.name);
                userListNode.append(
                    element
                );

                that.sort();
            }

        }
    };

    this.sort = function() {
        var users = userListNode.children('div');

        users.sort(function(a,b){
            var an = a.getAttribute('data-user-name'),
                bn = b.getAttribute('data-user-name');

            if(an > bn) {
                return 1;
            }
            if(an < bn) {
                return -1;
            }
            return 0;
        });

        users.detach().appendTo(userListNode);
    };

    this.refresh = function() {
        var time = that.chat.time;

        for(var userKey in that.users) {
            var user = that.users[userKey];

            if(user.lastActivity < time - howLong) {
                //console.log(userListNode.find('div').filter('[data-user-name="' + user.name + '"]').first().text());
                userListNode.find('div[data-user-name="' + user.name + '"]').remove();

                that.users.splice(userKey, 1);
            }
        }


    };

    setInterval(this.refresh, 1000);
}
ChatPluginUserList.prototype = ChatPlugin;

export {ChatPluginUserList}
